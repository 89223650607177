import { Link } from 'react-router-dom';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Logo from '../logo/Logo';

export default function Footer() {
  return (
    <footer className="bg-gray-900 w-full">
      <div className="w-full max-w-[85rem] py-10 px-4 sm:px-6 lg:px-8 lg:pt-20 mx-auto">
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-6">
          <div className="col-span-full lg:col-span-1">
            <Logo />
          </div>

          <div className="col-span-1">
            <h4 className="font-semibold text-gray-100">AI Med Info</h4>

            <div className="mt-3 grid space-y-3">
              <p>
                <Link
                  to="/login"
                  className="inline-flex gap-x-2 text-gray-400 hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                >
                  Login
                </Link>
              </p>
              <p>
                <a
                  className="inline-flex gap-x-2 text-gray-400 hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                  href="https://acmalifesciences.org/acma-ai/aimedinfo"
                >
                  More Info
                </a>
              </p>
              <p>
                <a
                  className="inline-flex gap-x-2 text-gray-400 hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                  href="https://acmalifesciences.org/acma-ai/aimedinfo#contact-us"
                >
                  Request a Quote
                </a>
              </p>
            </div>
          </div>

          <div className="col-span-1">
            <h4 className="font-semibold text-gray-100">Company</h4>

            <div className="mt-3 grid space-y-3">
              <p>
                <a
                  className="inline-flex gap-x-2 text-gray-400 hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                  href="https://medicalaffairsspecialist.org/about"
                >
                  About us
                </a>
              </p>
              <p>
                <a
                  className="inline-flex gap-x-2 text-gray-400 hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                  href="https://medicalaffairsspecialist.org/policy/terms-and-conditions"
                >
                  Terms & Conditions
                </a>
              </p>
              <p>
                <a
                  className="inline-flex gap-x-2 text-gray-400 hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                  href="https://medicalaffairsspecialist.org/policy/privacy"
                >
                  Privacy Policy
                </a>{' '}
              </p>
              <p>
                <a
                  className="inline-flex gap-x-2 text-gray-400 hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                  href="https://medicalaffairsspecialist.org/policy/academic-integrity"
                >
                  Academic Integrity Policy
                </a>
              </p>
            </div>
          </div>

          <div className="col-span-2">
            <h4 className="font-semibold text-gray-100">Stay up to date</h4>

            <div className="sm:mt-12 grid gap-y-2 sm:gap-y-0 sm:flex sm:justify-between sm:items-center">
              <div>
                <a
                  className="w-10 h-10 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-white hover:bg-white/10 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-1 focus:ring-gray-600"
                  href="https://www.linkedin.com/company/accreditation-council-for-medical-affairs-acma-/mycompany/verification/"
                >
                  <LinkedInIcon />
                </a>
                <a
                  className="w-10 h-10 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-white hover:bg-white/10 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-1 focus:ring-gray-600"
                  href="https://www.facebook.com/medicalaffairsspecialist/"
                >
                  <FacebookIcon />
                </a>
                <a
                  className="w-10 h-10 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-white hover:bg-white/10 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-1 focus:ring-gray-600"
                  href="https://twitter.com/i/flow/login?redirect_after_login=%2FThe_ACMA"
                >
                  <TwitterIcon />
                </a>
                <a
                  className="w-10 h-10 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-white hover:bg-white/10 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-1 focus:ring-gray-600"
                  href="https://www.instagram.com/acmapharma/"
                >
                  <InstagramIcon />
                </a>
                <a
                  className="w-10 h-10 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-white hover:bg-white/10 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-1 focus:ring-gray-600"
                  href="https://www.youtube.com/c/AccreditationCouncilforMedicalAffairs"
                >
                  <YouTubeIcon />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5 sm:mt-12 grid gap-y-2 sm:gap-y-0 justify-center sm:items-center">
          <div className="flex justify-between items-center">
            <p className="text-sm text-gray-400">© 2024 ACMA. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

import { Container } from '@mui/material';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import InsightsIcon from '@mui/icons-material/Insights';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import Footer from '../../components/ui/Footer';

export default function Home() {
  return (
    <>
      <Container>
        <section className="pt-5 pb-5">
          <div className="relative overflow-hidden before:absolute before:top-0 before:start-1/2 before:bg-no-repeat before:bg-top before:w-full before:h-full before:-z-[1] before:transform before:-translate-x-1/2">
            <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-10">
              <div className="flex justify-center">
                <a
                  className="inline-flex items-center gap-x-2 bg-white border border-gray-200 text-xs text-gray-600 p-2 px-3 rounded-full transition hover:border-gray-300 dark:bg-gray-800 dark:border-gray-700 dark:hover:border-gray-600 dark:text-gray-400"
                  href="https://acmalifesciences.org/acma-ai/aimedinfo"
                >
                  Explore AIMedInfo Product
                  <span className="flex items-center gap-x-1">
                    <span className="border-s border-gray-200 text-blue-600 ps-2 dark:text-blue-500">Explore</span>
                    <svg
                      className="flex-shrink-0 w-4 h-4 text-blue-600"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                    >
                      <path d="m9 18 6-6-6-6" />
                    </svg>
                  </span>
                </a>
              </div>

              <div className="mt-5 text-center mx-auto">
                <h1 className="block font-bold text-4xl md:text-5xl lg:text-5xl">
                  Build Your Medical Information Hub with AIMedInfo
                </h1>
              </div>

              <div className="mt-5 max-w-3xl text-center mx-auto">
                <p className="text-md text-gray-600 dark:text-gray-400">
                  Leverage cutting edge AI for swift and precise outsourcing of your medical information requirements.
                </p>
              </div>

              <div className="mt-8 gap-3 flex justify-center">
                <a
                  className="inline-flex justify-center items-center gap-x-3 text-center bg-gradient-to-tl from-blue-600 to-violet-600 hover:from-violet-600 hover:to-blue-600 border border-transparent text-white text-sm font-medium rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white py-3 px-4 dark:focus:ring-offset-gray-800"
                  href="https://acmalifesciences.org/acma-ai/aimedinfo#contact-us"
                >
                  Request a Quote
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-5 pb-5" style={{ marginTop: '40px' }}>
          <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
            <div className="lg:grid lg:grid-cols-12 lg:gap-16 lg:items-center">
              <div className="lg:col-span-7">
                <div className="grid grid-cols-12 gap-2 sm:gap-6 items-center lg:-translate-x-10">
                  <div className="col-span-4">
                    <img
                      className="rounded-xl"
                      src="https://images.unsplash.com/photo-1606868306217-dbf5046868d2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1981&q=80"
                      alt=" Description"
                    />
                  </div>
                  <div className="col-span-3">
                    <img
                      className="rounded-xl"
                      src="https://images.unsplash.com/photo-1605629921711-2f6b00c6bbf4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80"
                      alt=" Description"
                    />
                  </div>
                  <div className="col-span-5">
                    <img
                      className="rounded-xl"
                      src="https://images.unsplash.com/photo-1600194992440-50b26e0a0309?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80"
                      alt=" Description"
                    />
                  </div>
                </div>
              </div>

              <div className="mt-5 sm:mt-10 lg:mt-0 lg:col-span-5">
                <div className="space-y-6 sm:space-y-8">
                  <div className="space-y-2 md:space-y-4">
                    <h2 className="font-bold text-3xl lg:text-4xl">AI Powered Global MedInfo Solution</h2>
                    <p className="text-gray-500">
                      An AI enabled, Cutting-Edge End-to-End fully integrated Global Medical Information Solution.
                    </p>
                  </div>

                  <ul className="space-y-2 sm:space-y-4">
                    <li className="flex space-x-3">
                      <span className="mt-0.5 h-5 w-5 flex justify-center items-center rounded-full bg-blue-50 text-blue-600 dark:bg-blue-800/30 dark:text-blue-500">
                        <svg
                          className="flex-shrink-0 h-3.5 w-3.5"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                        >
                          <polyline points="20 6 9 17 4 12" />
                        </svg>
                      </span>

                      <span className="text-sm sm:text-base text-gray-500">
                        <span className="font-bold">AI Based Canned Questions</span> - Percise Answers
                      </span>
                    </li>

                    <li className="flex space-x-3">
                      <span className="mt-0.5 h-5 w-5 flex justify-center items-center rounded-full bg-blue-50 text-blue-600 dark:bg-blue-800/30 dark:text-blue-500">
                        <svg
                          className="flex-shrink-0 h-3.5 w-3.5"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                        >
                          <polyline points="20 6 9 17 4 12" />
                        </svg>
                      </span>

                      <span className="text-sm sm:text-base text-gray-500">AI Answers From Your Documents</span>
                    </li>

                    <li className="flex space-x-3">
                      <span className="mt-0.5 h-5 w-5 flex justify-center items-center rounded-full bg-blue-50 text-blue-600 dark:bg-blue-800/30 dark:text-blue-500">
                        <svg
                          className="flex-shrink-0 h-3.5 w-3.5"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                        >
                          <polyline points="20 6 9 17 4 12" />
                        </svg>
                      </span>

                      <span className="text-sm sm:text-base text-gray-500">
                        24/7 <span className="font-bold">Medical Information Chat Support</span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-5 pb-5" style={{ marginTop: '40px' }}>
          <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
            <div className="aspect-w-16 aspect-h-2">
              <img
                className="w-full object-cover rounded-xl"
                src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/aimedinfo/AIMedInfo_Typing.png"
                alt=" Description"
              />
            </div>

            <div className="mt-5 lg:mt-16 grid lg:grid-cols-3 gap-8 lg:gap-12">
              <div className="lg:col-span-1">
                <h2 className="font-bold text-2xl md:text-3xl">What AI MedInfo can do?</h2>
                <p className="mt-2 md:mt-4 text-gray-500">
                  AI MedInfo is a cutting-edge AI-enabled, end-to-end, fully-integrated Global Medical Information
                  Service Offering. Revolutionize the way medical information is accessed, managed, and utilized across
                  the healthcare landscape!
                </p>
              </div>

              <div className="lg:col-span-2">
                <div className="grid sm:grid-cols-2 gap-8 md:gap-12">
                  <div className="flex gap-x-5">
                    <SupportAgentIcon className={'text-blue-600'} />
                    <div className="grow">
                      <h3 className="text-lg font-semibold">Comprehensive Support</h3>
                      <p className="mt-1 text-gray-600 dark:text-gray-400">
                        Includes both AI Enabled Bots as well as Clinically Trained Live Chat Agents which support HCP
                        and patient questions and inquiries
                      </p>
                    </div>
                  </div>

                  <div className="flex gap-x-5">
                    <FolderOpenIcon className={'text-blue-600'} />
                    <div className="grow">
                      <h3 className="text-lg font-semibold">Effortless Data Retrieval</h3>
                      <p className="mt-1 text-gray-600 dark:text-gray-400">
                        Pulls data seamlessly from Standard Response Documents (SRDs), prescribing information and any
                        other sources provided.
                      </p>
                    </div>
                  </div>

                  <div className="flex gap-x-5">
                    <AltRouteIcon className={'text-blue-600'} />
                    <div className="grow">
                      <h3 className="text-lg font-semibold">Swift Response Routing</h3>
                      <p className="mt-1 text-gray-600 dark:text-gray-400">
                        Easy Routing to address inquiries which require real time responses for urgent requests or
                        Adverse Event Reporting
                      </p>
                    </div>
                  </div>

                  <div className="flex gap-x-5">
                    <InsightsIcon className={'text-blue-600'} />
                    <div className="grow">
                      <h3 className="text-lg font-semibold">Valuable Insights</h3>
                      <p className="mt-1 text-gray-600 dark:text-gray-400">
                        Collect valuable insights on chat content, identify information gaps, detect adverse events, and
                        more.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-5 pb-5" style={{ marginTop: '40px' }}>
          <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
            <div className="max-w-2xl mx-auto">
              <div className="grid gap-12">
                <div>
                  <h2 className="text-3xl font-bold lg:text-4xl">The Value of AI for Your Company</h2>
                  <p className="mt-3 text-gray-800 dark:text-gray-400">
                    Access information instantly with a Q&A chatbot, promote continuous learning for teams and patients,
                    and gather invaluable feedback for actionable insights.
                  </p>
                </div>

                <div className="space-y-6 lg:space-y-10">
                  <div className="flex">
                    <SmartToyOutlinedIcon />
                    <div className="ms-5 sm:ms-8">
                      <h3 className="text-base sm:text-lg font-semibold">Instant Access to Information</h3>
                      <p className="mt-1 text-gray-600 dark:text-gray-400">
                        Empowering people to move fast and effortless with a Q&A chatbot. Your team expert is available
                        24/7 for questions!
                      </p>
                    </div>
                  </div>

                  <div className="flex">
                    <LightbulbOutlinedIcon />
                    <div className="ms-5 sm:ms-8">
                      <h3 className="text-base sm:text-lg font-semibold">Continuous Learning</h3>
                      <p className="mt-1 text-gray-600 dark:text-gray-400">
                        Extend learning & ongoing development of team and patients. Questions answered, accurately and
                        dependably.
                      </p>
                    </div>
                  </div>

                  <div className="flex">
                    <ThumbUpOffAltIcon />
                    <div className="ms-5 sm:ms-8">
                      <h3 className="text-base sm:text-lg font-semibold">Invaluable Feedback</h3>
                      <p className="mt-1 text-gray-600 dark:text-gray-400">
                        Get data on all queries to turn that information into actionable insights. Fill in knowledge
                        gaps and action on patient queries in your yearly initiatives.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Container>
      <Footer />
    </>
  );
}

import PropTypes from 'prop-types';
// @mui
import { AppBar, Box, Container, Stack, Toolbar, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { Link, useNavigate } from 'react-router-dom';

import { bgBlur } from '../../../utils/cssStyles';
// components
//
import Logo from '../../../components/logo/Logo';
import useResponsive from '../../../hooks/useResponsive';

// ----------------------------------------------------------------------

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  padding: 0,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const isDesktop = useResponsive('up', 'md');
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <StyledRoot>
      <Container>
        <StyledToolbar disableGutters>
          {/* <IconButton
            onClick={onOpenNav}
            sx={{
              mr: 1,
              color: 'text.primary',
              display: { lg: 'none' },
            }}
          >
            <MenuIcon />
          </IconButton> */}
          <Box sx={{ width: '15%' }}>
            <Logo />
          </Box>

          <Box sx={{ flexGrow: 1 }} />

          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
          >
            {/* {isDesktop ? (
              <>
                <CNMAPInfoMenu />
              </>
            ) : (
              <></>
            )} */}
            <Link className="pr-2" size="large" to={'https://acmalifesciences.org/acma-ai/aimedinfo#contact-us'}>
              <Typography sx={{ color: 'black', fontSize: '14px' }}>Get Started</Typography>
            </Link>

            <div className="mt-8 gap-3 flex justify-center">
              <button
                type="submit"
                onClick={(e) => navigate('/login')}
                className="inline-flex justify-center items-center gap-x-3 text-center bg-gradient-to-tl from-blue-600 to-violet-600 hover:from-violet-600 hover:to-blue-600 border border-transparent text-white text-sm font-medium rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white py-3 px-4 dark:focus:ring-offset-gray-800"
                href="https://acmalifesciences.org/acma-ai/aimedinfo#contact-us"
              >
                Login
              </button>
            </div>
            {/* <Button
              size="large"
              sx={{
                ml: 3,
              }}
              onClick={(e) => navigate('/login')}
            >
              Login
            </Button> */}
          </Stack>
        </StyledToolbar>
      </Container>
    </StyledRoot>
  );
}

import LockIcon from '@mui/icons-material/Lock';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// @mui
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { LoadingButton } from '@mui/lab';
import { Box, Button, IconButton, InputAdornment, Stack, TextField, Typography, useTheme } from '@mui/material';
import { Auth } from 'aws-amplify';
import { toast } from 'react-hot-toast';
// components
import Iconify from '../../../components/iconify';
import { loginValidate } from './loginValidate';
import { newPasswordValidate } from './newPasswordValidate';
import { signInUser } from './signInUser';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setname] = useState('');

  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

  const [resetPassword, setResetPassword] = useState(false);

  const handleClick = async () => {
    const err = loginValidate(email, password);
    if (!err.message) {
      const result = await signInUser(email, password);
      if (result && result.success === true) {
        if (result.user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setResetPassword(true);
        } else {
          navigate('/app');
        }
      } else {
        toast.error(result.error.message || 'Error loggin in! Please try again');
      }
    } else {
      console.log('Error exists');
      toast.error(err.message);
    }
  };

  const resetPasswordHandler = async () => {
    const err = newPasswordValidate(name, newPassword, newPasswordConfirm);
    if (!err.message) {
      const result = await signInUser(email, password);
      console.log(result);
      if (result && result.success === true) {
        if (result.user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          const loggedUser = await Auth.completeNewPassword(
            result.user, // the Cognito User Object
            newPassword, // the new password
            {
              name,
            }
          );

          navigate('/app');
          toast.success('Password successfully changed!');
        }
      } else {
        toast.error(result.error.message || 'Error loggin in! Please try again');
      }
    } else {
      toast.error(err.message);
    }
  };

  return !resetPassword ? (
    <>
      <Box>
        <Button component={Link} to="/">
          <KeyboardBackspaceIcon style={{ marginRight: '8px' }} />
          Back
        </Button>
      </Box>
      <Typography variant="h4" gutterBottom>
        AIMedInfo Admin Portal
      </Typography>

      <Typography variant="body2" sx={{ mb: 5 }}>
        Don’t have an account? {''}
        <Link variant="subtitle2" to="https://acmalifesciences.org/acma-ai/aimedinfo#contact-us">
          Contact us to get started
        </Link>
      </Typography>
      <Stack spacing={3}>
        <TextField name="email" label="Email address" onChange={(e) => setEmail(e.target.value)} />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Button component={Link} to="/forgot-password">
          Forgot Password?
        </Button>
        {/* <Link variant="h7" underline="hover" to="/forgot-password">
          Forgot password?
        </Link> */}
      </Stack>

      <div className="mt-8 gap-3 flex justify-center">
        <button
          type="submit"
          onClick={handleClick}
          className="w-full inline-flex justify-center items-center gap-x-3 text-center bg-gradient-to-tl from-blue-600 to-violet-600 hover:from-violet-600 hover:to-blue-600 border border-transparent text-white text-sm font-medium rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white py-3 px-4 dark:focus:ring-offset-gray-800"
        >
          Login
        </button>
      </div>
    </>
  ) : (
    <>
      <Typography variant="h4" gutterBottom>
        <LockIcon /> Password Reset Required
      </Typography>
      <Typography variant="body2" sx={{ mb: 5 }}>
        Create a password that is both strong and easy to remember
      </Typography>
      <form noValidate>
        <Stack spacing={3}>
          <TextField
            inputProps={{
              autoComplete: 'off',
            }}
            name="full name"
            label="Full Name"
            onChange={(e) => setname(e.target.value)}
          />

          <TextField
            name="password"
            label="New Password"
            type={showPassword ? 'text' : 'password'}
            onChange={(e) => setNewPassword(e.target.value)}
            InputProps={{
              autoComplete: 'new-password',
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            name="password"
            label="New Password Confirm"
            type={showPassword ? 'text' : 'password'}
            onChange={(e) => setNewPasswordConfirm(e.target.value)}
            InputProps={{
              autoComplete: 'new-password',
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </form>
      <button
        type="submit"
        onClick={resetPasswordHandler}
        className="mt-5 w-full inline-flex justify-center items-center gap-x-3 text-center bg-gradient-to-tl from-blue-600 to-violet-600 hover:from-violet-600 hover:to-blue-600 border border-transparent text-white text-sm font-medium rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white py-3 px-4 dark:focus:ring-offset-gray-800"
      >
        Change Password
      </button>
    </>
  );
}
